import { Consumer } from '../types/consumer';
import { PaymentType } from '../types/enums';
import {
    Creditor,
    CreditorUpdate,
    Settlement,
    SettlementEdit,
    SettlementGridView,
    SettlementUpdate
} from '../types/settlements';
import { luxonDateToJSDate, serverDateToLuxonDateTime, luxonDateToServerDate } from './dateUtils';
import { defaultAddress } from './defaultData';
import { paymentTypeToString } from './stringUtils';

//*** Type conversion functions ***//
export const getSettlementEditFromSettlement = (
    settlement: Settlement,
    consumer: Consumer
): SettlementEdit => {
    //Set up a blank object for any null objects so value can be bound
    let settlementEdit = {
        ...settlement,
        dueDateTime: serverDateToLuxonDateTime(settlement.dueDate),
        payeeMailingDetails: settlement.payeeMailingDetails || {
            mailTo: '',
            payeeAddress: { ...defaultAddress }
        },
        payByPhoneDetails: settlement.payByPhoneDetails || {
            payeePhone: null,
            payeePayByPhoneContact: null,
            prepaid: false
        },
        bankAccount: settlement.bankAccount || {
            accountNumber: '',
            nameOnAccount: '',
            bankRouting: ''
        },
        bankAddress: settlement.bankAddress || { ...defaultAddress }
    };
    //If the consumerSsn is not populated, populates the last 4 digits of the consumer’s primary Tax Id
    if (settlementEdit.consumerSsnLast4.trim() === '' && consumer.contactInfo.primary.taxId) {
        settlementEdit.consumerSsnLast4 = consumer.contactInfo.primary.taxId;
    }
    return settlementEdit;
};

export const getSettlementGridViewFromSettlements = (
    settlements: Settlement[]
): SettlementGridView[] => {
    return settlements.map(settlement => {
        return {
            ...settlement,
            dueDateTime: luxonDateToJSDate(serverDateToLuxonDateTime(settlement.dueDate), true),
            sentText: settlement.sent ? 'Sent' : 'Not Sent',
            paymentTypeText: paymentTypeToString(settlement.paymentType),
            voidText: settlement.void ? 'Void' : 'Valid'
        };
    });
};

export const getSettlementUpdateFromSettlementEdit = (
    settlement: SettlementEdit, uid?: number
): SettlementUpdate => {
    let { dueDateTime, ...settlementUpdate } = settlement;
    
    settlementUpdate.dueDate = luxonDateToServerDate(dueDateTime);
    if(uid){
        settlementUpdate.CreditorAddressUid = uid;
    }
    
    //Check payment type and set properties conditional on payment type
    switch (settlementUpdate.paymentType) {
        case PaymentType.RamCheck:
            settlementUpdate.payByPhoneDetails = null;
            settlementUpdate.bankAccount = null;
            settlementUpdate.bankName = null;
            settlementUpdate.bankAddress = null;
            break;
        case PaymentType.WireTransfer:
            settlementUpdate.payByPhoneDetails = null;
            break;
        case PaymentType.Ach:
            settlementUpdate.payByPhoneDetails = null;
            settlementUpdate.payeeMailingDetails = null;
            settlementUpdate.bankName = null;
            settlementUpdate.bankAddress = null;
            break;
        case PaymentType.PayByPhone:
            settlementUpdate.bankAccount = null;
            settlementUpdate.bankName = null;
            settlementUpdate.bankAddress = null;
            break;
        default:
            break;
    }
    return settlementUpdate;
};

export const getCreditorUpdateFromCreditor = (creditor: Creditor): CreditorUpdate => {
    const { id, ...creditorUpdate } = creditor;
    return creditorUpdate;
};
