import React, { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import './ResetPassword.scss';
import { getUserDataFromToken } from '../../../authentication/authService';

export const ResetPassword = (props: any) => {

    const { addToast } = useToasts();
   const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );


    const sessionMigratedUserData = JSON.parse(sessionStorage.getItem('migratedUserData') ?? '{}');
    const [newtype, setNewType] = useState('password');
    const [confirmtype, setConfirmType] = useState('password');
    const [currenttype, setCurrentype] = useState('password');
    const [migratedUserData, setMigratedUserData] = useState(sessionMigratedUserData);
    const [newPassword, setnewPassword] = useState('');
    const [confimPassword, setconfimPassword] = useState('');
    const [currentPassword, setcurrentPassword] = useState('');
    const [apierrors, setapierrors] = useState<any>([]);

    const [errors, setErrors] = useState({
        newPassword: false,
        confimPassword: false,
        currentPassword: false,
        misMatch: false
    });

    const [error, setError] = useState(false);
    const [data, setData] = useState<any>({});

    const validatePassword = (password: string) => {
        const errors = [];
        
        if (password.length < 8) errors.push('Password must be at least 8 characters long.');
        if (!/[A-Z]/.test(password)) errors.push('Password must contain at least one uppercase letter (A-Z).');
        if (!/[a-z]/.test(password)) errors.push('Password must contain at least one lowercase letter (a-z).');
        if (!/\d/.test(password)) errors.push('Password must contain at least one digit (0-9).');
        if (!/\W/.test(password)) errors.push('Password must contain at least one special character (e.g., !, @, #, $, etc.).');
        return errors;
    };

    const validateAndChangePassword = () => {
        const passwordErrors = validatePassword(newPassword);
        if (passwordErrors.length > 0) {
            setErrors({
                ...errors,
            });
            setapierrors(passwordErrors);
            return;
        }
        changePassword();
    };

    const changePassword = () => {
        let errors: any = {
            currentPassword: false,
            newPassword: false,
            confimPassword: false,
            misMatch: false,
        };
        setErrors(errors);
        setError(false);
        if ((currentPassword === '' && migratedUserData.migratedUser != true)
            || newPassword === '' || confimPassword === '' || newPassword !== confimPassword) {
            let errors: any = {};
            if (currentPassword === '') {
                errors.currentPassword = true;
            }
            if (newPassword === '') {
                errors.newPassword = true;
            }
            if (confimPassword === '') {
                errors.confimPassword = true;
            }
            if (newPassword !== confimPassword) {
                errors.misMatch = true;
            }
            setErrors(errors);
        }
        else if (migratedUserData.migratedUser != true) {
            var formdata = new FormData();
            let userName = sessionStorage.getItem('userName') ?? '';
            formdata.append("Username", `${userName}`);
            formdata.append("Password", `${currentPassword}`);

            var requestOptions: any = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            const env = process.env;
            const LOGIN_URL = env.REACT_APP_LOGIN_PAGE_URL + "/validateuser";
            LOGIN_URL && fetch(LOGIN_URL, requestOptions)
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        return 'error';
                    }
                })
                .then((result: any) => {
                    if (result !== 'error') {
                        setData(result);
                    }
                    else {
                        setError(true);
                    }
                })
                .catch(error => console.log('error', error));
        }
        else {
            setData(migratedUserData);
        }
    };

    const [passwordReset, setPasswordReset] = useState(false);

    useEffect(() => {
        if (data.token) {
            var { userId, email } = getUserDataFromToken(data.token);
            savePassword(userId ?? '', email ?? '');
        }

    }, [data]);

    const savePassword = (userId: string, email: string) => {
        var formdata = new FormData();
        let userName = sessionStorage.getItem('userName') ?? '';
        formdata.append("ConfirmPassword", `${confimPassword}`);
        formdata.append("Password", `${newPassword}`);
        formdata.append("Expired", `false`);
        formdata.append("ReturnUrl", `/`);
        formdata.append("Token", `${data.resetToken}`);
        formdata.append("UserId", `${userId}`);
        formdata.append("Email", `${email}`);

        var requestOptions: any = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        const SAVE_PASSWORD_URL = process.env.REACT_APP_LOGIN_PAGE_URL + "/ApiResetPassword";
        fetch(SAVE_PASSWORD_URL, requestOptions)
            .then((result: any) => {
                setapierrors([]);
                if (result.status === 200) {
                    setPasswordReset(true);
                    sessionStorage.removeItem('migratedUserData');
                }
                else {
                    result.json().then((json: any) => {
                        if (json.errors) {
                            let apierrors = [];
                            for (let obj in json.errors) {
                                apierrors.push(json.errors[obj]?.[0]);
                            }
                            apierrors && setapierrors(apierrors);
                        }
                    });
                }
            });
    };

    return <>
        {!passwordReset ? <div className='reset-page card'>
            <img className='logo' src='https://reliantpayment.com/wp-content/themes/reliant/assets/img/main-logo.svg'></img>
            {migratedUserData.migratedUser != true ? <div className='row'>
                Your password is expired. Please reset to continue.
            </div> : <div className='row'>
                Your account has been migrated. Please set your password to continue.
            </div>
            }
            <div className='password-requirements'>
                <p>Please note that the new password should meet the criteria mentioned below:</p>
                <ul>
                    <li>Must be at least 8 characters long.</li>
                    <li>Must contain at least one uppercase letter (A-Z).</li>
                    <li>Must contain at least one lowercase letter (a-z).</li>
                    <li>Must contain at least one digit (0-9).</li>
                    <li>Must contain at least one special character (e.g., !, @, #, $, etc.).</li>
                </ul>
            </div>
            {migratedUserData.migratedUser != true ? <div className='row'>
                <input type={currenttype} placeholder='Current Password' onChange={(e) => setcurrentPassword(e.target.value)}></input>
                <span className={`e-icons e-eye${currenttype == 'text' ? '' : '-slash'}`} onClick={() => {
                    if (currenttype === "text") setCurrentype("password")
                    else setCurrentype("text")
                }}></span>
            </div> : ''
            }
            <div className={` ${errors.currentPassword ? 'error' : 'hide'}`}>Please enter Current Password</div>
            <div className='row'>
                <input type={newtype} placeholder='New Password' onChange={(e) => setnewPassword(e.target.value)}></input>
                <span className={`e-icons e-eye${newtype == 'text' ? '' : '-slash'}`} onClick={() => {
                    if (newtype === "text") setNewType("password")
                    else setNewType("text")
                }}></span>
            </div>
            <div className={` ${errors.newPassword ? 'error' : 'hide'}`}>Please enter New Password</div>
            <div className='row'>
                <input type={confirmtype} placeholder='Confirm New Password' onChange={(e) => setconfimPassword(e.target.value)}></input>
                <span className={`e-icons e-eye${confirmtype == 'text' ? '' : '-slash'}`} onClick={() => {
                    if (confirmtype === "text") setConfirmType("password")
                    else setConfirmType("text")
                }}></span>
            </div>
            <div className={` ${errors.confimPassword || error || errors.misMatch ? 'error' : 'hide'}`}>{errors.confimPassword ? 'Please enter Confirm Password' : error ? 'Invalid Current Password' : errors.misMatch ? 'New password does not match the confirm password.' : ''}</div>
            <div className='errors-block'>{
                apierrors.length > 0 && apierrors.map((error: any, index: any) => {
                    return <div key={index} className='error'>{error}</div>
                })
            }</div>
            <div className='row'>
                <button onClick={(() => {
                    validateAndChangePassword();
                })}>Change Password</button>
            </div>
        </div> : <div className='reset-page card'> <img className='logo' src='https://reliantpayment.com/wp-content/themes/reliant/assets/img/main-logo.svg'></img>
            <p>The password has been successfully reset. Click here to <a href='/login?returnUrl=/'>Login</a></p>
        </div>}
    </>
}